import React, { useState } from 'react'
import {
  Button,
  useErrorService,
  useUserService,
  useNotificationService,
} from '@abroad/components'
import { AssignCoachModal } from '../common'
import API from '../../utils/API'

const AssignNewCoach = ({
  indiSOWCoachIds,
  userInfo,
  assignNewCoachHandler,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const Error = useErrorService()
  const { user } = useUserService()
  const Notification = useNotificationService()

  const handleAssignCoach = async (coach, coaches) => {
    setIsLoading(true)
    const selectedCoach = coaches.find((e) => e?._id === coach)
    try {
      const { data } = await API.AdminAPI.coachingList.assignClient(
        userInfo?._id,
        coach,
        {
          forceAssign: false,
        },
      )
      if (data) {
        Notification.showNotification(
          'User has been assigned to coach!',
          'success',
        )
        assignNewCoachHandler(selectedCoach)
        setShowModal(false)
        setIsLoading(false)
      }
    } catch (e) {
      setIsLoading(false)
      Error.showError(e)
    }
  }

  return (
    <React.Fragment>
      <AssignCoachModal
        fullName={userInfo?.fullName}
        handleAssignCoach={handleAssignCoach}
        indiSOWCoachIds={indiSOWCoachIds}
        isSubmitting={isLoading}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <div className='d-flex align-items-center'>
        {userInfo?.preference && user?.isAdmin && (
          <div className='me-10px custom-heading'>
            (<span>Preference : {userInfo?.preference}</span>)
          </div>
        )}
        {userInfo?.isShowCoachAssignBtn && user?.isAdmin && (
          <Button
            variant='gray'
            className='line-height-normal'
            size='md'
            disabled={isLoading.isSubmitting}
            isLoading={isLoading.isSubmitting}
            onClick={() => setShowModal(true)}>
            Assign New Coach
          </Button>
        )}
      </div>
    </React.Fragment>
  )
}

export default AssignNewCoach
