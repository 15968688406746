import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useErrorService, sows } from '@abroad/components'
import ClientSOWTable from './ClientSOWTable'
import AssignNewCoach from './AssignNewCoach'
import API from '../../utils/API'
import labels from '../../constants/labels'
import { maxCoachingPlans } from '../../utils/coachingPlanHelper'

const planType = sows.planType

const ClientSOWs = ({
  userPlan,
  isUserPlanExpired,
  coachesHasNoSOW = [],
  coachesHasSOW = [],
  coachIds = [],
  userInfo,
  addNewCoachHandler,
  setClientProfile,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [clientIndividualSOWs, setClientIndividualSOWs] = useState([])
  const [indiSOWCoachIds, setIndiSOWCoachIds] = useState(coachIds)
  const [clientB2BSOWs, setClientB2BSOWs] = useState([])
  const Error = useErrorService()
  const { clientId } = useParams()
  const key = 'includeDeleted'

  const sortByCoachName = (data, individualPlans = false) => {
    //data.sort((a, b) => a.planName.localeCompare(b.planName))
    return data.sort(function (a, b) {
      const aKey = individualPlans
        ? a?.coachId?.fullName
        : a?.coaches[0]?.coachId?.fullName
      const bKey = individualPlans
        ? b?.coachId?.fullName
        : b?.coaches[0]?.coachId?.fullName
      if (aKey < bKey) {
        return -1
      }
      if (aKey > bKey) {
        return 1
      }
      return 0
    })
  }

  useEffect(() => {
    const getClientSOWs = async () => {
      setClientIndividualSOWs([])
      setClientB2BSOWs([])
      setIsLoading(true)
      try {
        const { data } = await API.AdminAPI.coachPlan.getClientsCoachingPlans(
          clientId,
          `?${key}=true`,
        )
        if (data) {
          const individualPlanData = data?.filter(
            (plan) => plan?.type === planType.individual,
          )
          const b2bPlanData = data?.filter(
            (plan) => plan?.type === planType.b2b,
          )
          let indiSOW = []
          const coaches = coachesHasNoSOW
            ? coachesHasNoSOW?.map((coach) => ({
                isCoachOfCurrentPlan: true,
                coachId: {
                  _id: coach?._id,
                  fullName: coach?.fullName,
                  isStaffCoach: coach?.isStaffCoach || false,
                },
                type: planType.individual,
              }))
            : []

          const sortedCoaches = coaches.sort((a, b) =>
            a?.coachId?.fullName.localeCompare(b?.coachId?.fullName),
          )

          const activeB2BSOW = b2bPlanData.filter((e) => !e.isDeleted)

          const inactiveB2BSOW = b2bPlanData.filter((e) => e.isDeleted)

          const sortedActiveB2BPlanData = sortByCoachName(activeB2BSOW)

          const sortedInactiveB2BPlanData = sortByCoachName(inactiveB2BSOW)

          coachesHasSOW.forEach((coach) => {
            const coachId = coach?._id
            const coachPlans = individualPlanData.filter(
              (plan) => plan.coachId._id === coachId,
            )
            const activeCoachPlans = coachPlans.filter(
              (plan) => plan.status === labels.coachingPlanStatus[1].value,
            )
            const inactiveCoachPlans = coachPlans.filter(
              (plan) => plan.status !== labels.coachingPlanStatus[1].value,
            )
            const isShowAddSessionLink = coachPlans.some(
              (plan) =>
                plan?.isCoachOfCurrentPlan &&
                [
                  labels.coachingPlanStatus[0].value,
                  labels.coachingPlanStatus[1].value,
                ].includes(plan?.status), //when Sow status is in Draft or In process
            )
            indiSOW.push({
              coachId: {
                _id: coach?._id,
                fullName: coach?.fullName,
                isStaffCoach: coach?.isStaffCoach || false,
              },
              coachPlans: [...activeCoachPlans, ...inactiveCoachPlans],
              type: planType.individual,
              isDisabled: coachPlans?.length >= maxCoachingPlans,
              isShowAddSessionLink,
            })
          })
          const sortedIndividualPlanData = sortByCoachName(indiSOW, true) //individualPlans=true
          setClientIndividualSOWs([
            ...sortedIndividualPlanData,
            ...sortedCoaches,
          ])
          setClientB2BSOWs([
            ...sortedActiveB2BPlanData,
            ...sortedInactiveB2BPlanData,
          ])
        }
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        Error.showError(e)
      }
    }
    if (clientId && isLoading) getClientSOWs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, isLoading])

  return (
    <React.Fragment>
      <div className='client-sows'>
        <div className='d-flex justify-content-between mt-2-5 title'>
          <h3 className='s3 mb-0'>
            Client {sows.label.individual} Coaches & SOWs
          </h3>
          <div>
            <AssignNewCoach
              indiSOWCoachIds={indiSOWCoachIds}
              userInfo={userInfo}
              assignNewCoachHandler={(coach) => {
                const newCoach = {
                  fullName: coach?.fullName,
                  _id: coach?._id,
                  isStaffCoach: coach?.isStaffCoach || false,
                }
                setClientProfile((prev) => ({
                  ...prev,
                  coachIds: [
                    //{ ...(prev.coachIds && { ...prev.coachIds }) },
                    ...prev.coachIds,
                    { ...newCoach, b2bPlans: [], isIndiCoach: true },
                  ],
                }))
                addNewCoachHandler(newCoach)
                setIndiSOWCoachIds([...indiSOWCoachIds, coach?._id])
                setClientIndividualSOWs([
                  ...clientIndividualSOWs,
                  {
                    isCoachOfCurrentPlan: true,
                    coachId: {
                      _id: coach?._id,
                      fullName: coach?.fullName,
                      isStaffCoach: coach?.isStaffCoach || false,
                    },
                    type: planType.individual,
                  },
                ])
              }}
            />
          </div>
        </div>
        <ClientSOWTable
          userPlan={userPlan}
          isUserPlanExpired={isUserPlanExpired}
          setClientSOWs={setClientIndividualSOWs}
          clientSOWs={clientIndividualSOWs}
          isLoading={isLoading}
        />
        <h3 className='mt-2-5 s3 title'>
          Client {sows.label.b2b} Coaches & SOWs
        </h3>
        <ClientSOWTable
          userPlan={userPlan}
          isUserPlanExpired={isUserPlanExpired}
          setClientSOWs={setClientB2BSOWs}
          clientSOWs={clientB2BSOWs}
          isLoading={isLoading}
          isB2BPlanTable={true}
        />
      </div>
    </React.Fragment>
  )
}

export default ClientSOWs
